// @flow
// @flow-runtime enable
import { get, put, post, del, patch } from 'lib/request';
import { ROOT_API_HOST, API_HOST } from 'config';
import { type OrderState } from 'types/entities/OrderState';
import { type Product } from 'types/entities/Product';
import idx from 'idx';
import queryString from 'query-string';

export const fetchOrders = async (query: any) => {
  try {
    const response = await get(`${API_HOST}/orders?${queryString.stringify(query)}`).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const fetchOrder = async (orderId: number) => {
  try {
    const response = await get(`${API_HOST}/orders/${orderId}`).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const changeOrderState = async (orderId: number, state: OrderState) => {
  try {
    const response = await put(`${API_HOST}/orders/${orderId}/state`, state, {
      headers: {
        'Content-Type': 'plain/text',
      },
    }).then(res => res.data);
    return response;
  } catch (error) {
    if (idx(error, _ => _.response.data.message)) {
      return Promise.reject(error.response.data.message);
    }
    return null;
  }
};

export const changeOrderDisbandDate = async (orderId: number, date: OrderState) => {
  try {
    const response = await put(`${API_HOST}/orders/${orderId}/change_disband_date`, date).then(res => res.data);
    return response;
  } catch (error) {
    if (idx(error, _ => _.response.data.message)) {
      return Promise.reject(error.response.data.message);
    }
    return null;
  }
};

export const changeOrder = async (orderId: number, data: any) => {
  try {
    const response = await patch(`${API_HOST}/orders/${orderId}`, data).then(res => res.data);
    return response;
  } catch (error) {
    if (idx(error, _ => _.response.data.message)) {
      return Promise.reject(error.response.data.message);
    }
    return null;
  }
};

export const changeOrderLineQuantity = async (
  orderId: number,
  lineId: number,
  quantity: number,
) => {
  try {
    const response = await put(`${API_HOST}/orders/${orderId}/lines/${lineId}`, quantity, {
      headers: {
        'Content-Type': 'plain/text',
      },
    }).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const addProduct = async (product: Product, orderId: number) => {
  try {
    const response = await post(`${API_HOST}/orders/${orderId}/lines`, {
      product,
      quantity: 1,
    }).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const removeOrderLine = async (orderId: number, lineId: number) => {
  try {
    const response = await del(`${API_HOST}/orders/${orderId}/lines/${lineId}`).then(
      res => res.data,
    );
    return response;
  } catch (error) {
    return null;
  }
};
export const assemblyReport = async (data: any) => {
  try {
    const response = await post(`${ROOT_API_HOST}/assembly_reports`, data).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

export const recalculateToDate = async (orderId: number, data: any) => {
  try {
    const response = await put(
      `${API_HOST}/orders/${orderId}/recalculate?${queryString.stringify(data)}`,
      {},
    ).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};


export const orderApologize = async (orderId: number) => {
  try {
    const response = await post(
      `${API_HOST}/orders/${orderId}/apologize`,
      {},
    ).then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};
