Comment; // @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import CommentIcon from '@material-ui/icons/Comment';
import { type Theme } from 'types/components/Theme';
import moment from 'moment';
import { DatePicker } from 'components/date-picker';
import { changeOrderDisbandDate } from 'services/orderApi';

const styles = (theme: Theme) => ({
  root: { padding: theme.spacing.unit },
});

const DisbandDate = ({ classes, order, onChanage }) => {
  if (!order) return null;

  const handleChange = disband_date => {
    changeOrderDisbandDate(order.id, {
      date: disband_date ? disband_date.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
    }).then(() => onChanage());
  };
  return (
    <div className={classes.root}>
      <DatePicker
        formatFrom="YYYY.MM.DD HH:mm"
        from={order.disband_date}
        setFrom={handleChange}
        labelFrom="Дата расформирования"
      />
    </div>
  );
};
export default withStyles(styles)(DisbandDate);
